.App {
  height: 100vh;
  width: 96vw;
  position: relative;
  margin: 0 auto;
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

p {
  margin: 0;
  line-height: 1;
}

h1 {
  margin: 0;
  margin-bottom: .25em;
  font-size: 1.5em;
}

.map {
  width: 100%;
  height: 100%;
}

.arrow-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid currentColor;
  transition: transform 0.2s;
  transform: rotate(-90deg);
}

.arrow-icon.down {
  transform: rotate(0deg);
}

.pinball-machines-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0.5rem 0;
  color: #4a5568;
}

.pinball-machine-names-list {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0;
}

.pinball-machine-name {
  padding: 0.25rem 0;
  color: #4a5568;
  font-size: 0.875rem;
}

/* dropdown stuff */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown button {
  background: white;
  border: 1px solid #e2e8f0;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.875rem;
  color: #4a5568;
  transition: all 0.2s;
}

.dropdown button:hover {
  background: #f7fafc;
  border-color: #cbd5e0;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-top: 0.25rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 10;
  min-width: 200px;
}

.dropdown-content label {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  cursor: pointer;
  transition: background 0.2s;
  color: #4a5568;
  font-size: 0.875rem;
}

.dropdown-content label:hover {
  background: #f7fafc;
}

.dropdown-content input[type="checkbox"] {
  margin-right: 0.5rem;
}

select {
  background: white;
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;
}

select:hover {
  border-color: #cbd5e0;
}

label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
  font-size: 0.875rem;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.map-marker {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  white-space: nowrap;
  cursor: pointer;
}

.charger-marker {
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid #4299e1;
  color: #2b6cb0;
}

.pinball-marker {
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid #ed64a6;
  color: #b83280;
}

/* Popup styles */
.mapboxgl-popup-content {
  padding: 1rem;
  border-radius: 0.5rem;
}

.mapboxgl-popup-content h1 {
  font-size: 1.125rem;
  margin: 0 0 0.5rem 0;
  color: #2d3748;
}

.mapboxgl-popup-content p {
  margin: 0.25rem 0;
  color: #4a5568;
}

.mapboxgl-popup-content a {
  color: inherit;
  text-decoration: none;
}

.mapboxgl-popup-content a:hover {
  text-decoration: underline;
}

.charger-connector-list {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.charger-connector {
  background: #ebf8ff;
  color: #2b6cb0;
  padding: 0.125rem 0.375rem;
  border-radius: 1rem;
  font-size: 0.75rem;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #2d3748;
}

.subtitle {
  font-size: 0.875rem;
  color: #718096;
  margin: 0;
}

.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Geocoder styles */
.mapboxgl-ctrl-geocoder {
  min-width: 250px !important;
  font-family: inherit !important;
}

.mapboxgl-ctrl-geocoder--input {
  height: 36px !important;
  padding: 6px 35px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 8px !important;
}

.mapboxgl-ctrl-geocoder--button {
  top: 3px !important;
}